import axios from 'axios'

export default {
  updateNews (news) {
    return axios.put('news/' + news.id, news).then(response => {
      return response.data
    })
  },

  createNews (news) {
    return axios.post('news', news).then(response => {
      return response.data
    })
  },

  deleteNews (id) {
    return axios.delete('news/' + id).then(response => {
      return response.data
    })
  },

  fetchNews (id) {
    return axios.get('news/' + id + '?expand=catImages,catImagesCount,catImagesIds')
      .then(response => {
        return response.data
      })
  },

  fetchNewses () {
    return axios.get('news?expand=newsCategory,catImagesCount,catImagesIds').then(response => {
      return response.data
    })
  },

  fetchNewsCategories () {
    return axios.get('newscategories').then(response => {
      return response.data
    })
  },
}
