var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "newsslist", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "base-material-card",
                {
                  attrs: { color: "success", icon: "mdi-badge-account" },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "50%" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "card-title font-weight-light"
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Новости\n                "
                                          ),
                                          _c(
                                            "v-icon",
                                            { staticClass: "help-pointer" },
                                            [
                                              _vm._v(
                                                "\n                  mdi-help-circle-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Новости центра")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "3" }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label: "Поиск новостей",
                                          "single-line": "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "help-pointer pt-5 pb-5 pl-2 pr-5",
                                          attrs: { title: "Очистить поиск" },
                                          on: {
                                            click: function($event) {
                                              _vm.search = ""
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    mdi-close\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0 pl-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: { color: "secondary", rounded: "" },
                                  on: { click: _vm.createNews }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n                  mdi-plus\n                "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n                Добавить\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "pt-0 pb-0" }),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "12", md: "auto" }
                            },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 mb-0",
                                attrs: { label: "Показать архивные" },
                                model: {
                                  value: _vm.onlyArchived,
                                  callback: function($$v) {
                                    _vm.onlyArchived = $$v
                                  },
                                  expression: "onlyArchived"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loading,
                      "loading-text": "Загрузка новостей...",
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "items-per-page": _vm.itemsPerPage,
                      page: _vm.page,
                      "sort-by": ["id"],
                      "sort-desc": [true]
                    },
                    on: {
                      "update:itemsPerPage": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-items": _vm.getFiltered
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          return [
                            _c(
                              "tbody",
                              _vm._l(items, function(item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item.id,
                                    class: { archived: !item.status },
                                    attrs: { id: "news_" + item.id }
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleNews(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.id) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-top",
                                        on: {
                                          click: function($event) {
                                            if (
                                              $event.target !==
                                              $event.currentTarget
                                            ) {
                                              return null
                                            }
                                            return _vm.singleNews(item)
                                          }
                                        }
                                      },
                                      [
                                        item.catImagesCount > 0
                                          ? _c("v-img", {
                                              staticClass: "grey lighten-2",
                                              attrs: {
                                                src: _vm.imageLink(
                                                  item.catImagesIds[0]
                                                ),
                                                "lazy-src": _vm.imageLink(
                                                  item.catImagesIds[0]
                                                ),
                                                "aspect-ratio": "1.5",
                                                width: "100px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.singleNews(item)
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "placeholder",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "fill-height ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                  color:
                                                                    "grey lighten-5"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            if (
                                              $event.target !==
                                              $event.currentTarget
                                            ) {
                                              return null
                                            }
                                            return _vm.singleNews(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "display-1" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.name) +
                                                "\n                    "
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-0 pr-0",
                                                attrs: {
                                                  icon: "",
                                                  href: _vm.singleNewsLink(
                                                    item
                                                  ),
                                                  target: "_blank",
                                                  title:
                                                    "Открыть новость на сайте"
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-open-in-new")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(item.text)
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.singleNews(item)
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleNews(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("formatDateFromUnixTime")(
                                                item.create_time
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.singleNews(item)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.newsCategory.name) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-right" },
                                      [
                                        item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "error",
                                                  "min-width": "0",
                                                  title: "Переместить в архив",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirmArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-close\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !item.status
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "px-2 ml-1",
                                                attrs: {
                                                  color: "info",
                                                  "min-width": "0",
                                                  title: "Вернуть из архива",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.confirmUnArchive(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                      mdi-restore\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.newsDialog,
                    callback: function($$v) {
                      _vm.newsDialog = $$v
                    },
                    expression: "newsDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            "\n            Добавление нового клиента\n\n            "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.newsDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-5",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Заголовок",
                                          "error-messages": _vm.fieldError(
                                            "name"
                                          )
                                        },
                                        model: {
                                          value: _vm.news.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.news, "name", $$v)
                                          },
                                          expression: "news.name"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Текст",
                                          "error-messages": _vm.fieldError(
                                            "text"
                                          )
                                        },
                                        model: {
                                          value: _vm.news.text,
                                          callback: function($$v) {
                                            _vm.$set(_vm.news, "text", $$v)
                                          },
                                          expression: "news.text"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Новость со ссылкой на",
                                          "error-messages": _vm.fieldError(
                                            "url"
                                          )
                                        },
                                        model: {
                                          value: _vm.news.url,
                                          callback: function($$v) {
                                            _vm.$set(_vm.news, "url", $$v)
                                          },
                                          expression: "news.url"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                color: "secondary",
                                rounded: "",
                                "min-width": "100"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.create()
                                }
                              }
                            },
                            [
                              _vm._v("\n              Далее\n              "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(
                                  "\n                mdi-arrow-right\n              "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmArchiveDialog,
            callback: function($$v) {
              _vm.confirmArchiveDialog = $$v
            },
            expression: "confirmArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Переместить новость в архив?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После помещения в архив новость не будет отображаться на сайте.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.archive(_vm.selectedNews)
                        }
                      }
                    },
                    [_vm._v("\n          Переместить в архив\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450" },
          model: {
            value: _vm.confirmUnArchiveDialog,
            callback: function($$v) {
              _vm.confirmUnArchiveDialog = $$v
            },
            expression: "confirmUnArchiveDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Вернуть новость из архива?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        После возврата новости из архива он снова будет отображаться на сайте.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmUnArchiveDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.unarchive(_vm.selectedNews)
                        }
                      }
                    },
                    [_vm._v("\n          Вернуть из архива\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }