<template>
  <v-container
    id="newsslist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Новости
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Новости центра</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
                class="pt-0 pb-0"
              >
                <v-row>
                  <v-col
                    class="pa-0 ma-0"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск новостей"
                      single-line
                      hide-details
                    />
                  </v-col>
                  <v-col
                    class="pa-0 ma-0"
                    cols="auto"
                  >
                    <v-icon
                      title="Очистить поиск"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="search = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <!--
              <v-col
                cols="12"
                md="2"
                class="pt-0 pb-0"
              >
                <v-select
                  v-model="newsCategoryFilter"
                  color="secondary"
                  item-color="secondary"
                  label="Статус"
                  item-value="tag"
                  item-text="name"
                  multiple
                  :items="newsCategories"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-2 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          item-value="item.id"
                          return-object
                          v-text="item.name"
                        />
                      </v-list-item-content>

                      <v-scale-transition>
                        <v-list-item-icon
                          v-if="attrs.inputValue"
                          class="my-3"
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-list-item-icon>
                      </v-scale-transition>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              -->
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="createNews"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  label="Показать архивные"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка новостей..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['id']"
            :sort-desc="[true]"
            @current-items="getFiltered"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :id="'news_' + item.id"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                >
                  <!-- 1 -->
                  <td @click="singleNews(item)">
                    {{ item.id }}
                  </td>
                  <!-- 2 -->
                  <td
                    class="text-top"
                    @click.self="singleNews(item)"
                  >
                    <v-img
                      v-if="item.catImagesCount > 0"
                      :src="imageLink(item.catImagesIds[0])"
                      :lazy-src="imageLink(item.catImagesIds[0])"
                      aspect-ratio="1.5"
                      width="100px"
                      class="grey lighten-2"
                      @click="singleNews(item)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </td>
                  <td @click.self="singleNews(item)">
                    <div class="display-1">
                      {{ item.name }}
                      <v-btn
                        icon
                        :href="singleNewsLink(item)"
                        target="_blank"
                        title="Открыть новость на сайте"
                        class="mr-0 pr-0"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </div>
                    <div
                      @click="singleNews(item)"
                      v-html="item.text"
                    />
                  </td>
                  <td @click="singleNews(item)">
                    {{ item.create_time | formatDateFromUnixTime }}
                  </td>
                  <td @click="singleNews(item)">
                    {{ item.newsCategory.name }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-if="item.status"
                      color="error"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Переместить в архив"
                      small
                      @click="confirmArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!item.status"
                      color="info"
                      class="px-2 ml-1"
                      min-width="0"
                      title="Вернуть из архива"
                      small
                      @click="confirmUnArchive(item)"
                    >
                      <v-icon
                        small
                      >
                        mdi-restore
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </base-material-card>

        <v-dialog
          v-model="newsDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              Добавление нового клиента

              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="newsDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-5"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="news.name"
                      required
                      label="Заголовок"
                      :error-messages="fieldError('name')"
                    />

                    <v-text-field
                      v-model="news.text"
                      label="Текст"
                      :error-messages="fieldError('text')"
                    />

                    <v-text-field
                      v-model="news.url"
                      label="Новость со ссылкой на"
                      :error-messages="fieldError('url')"
                    />
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                color="secondary"
                rounded
                min-width="100"
                @click="create()"
              >
                Далее
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Переместить новость в архив?
        </v-card-title>

        <v-card-text>
          После помещения в архив новость не будет отображаться на сайте.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="archive(selectedNews)"
          >
            Переместить в архив
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUnArchiveDialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">
          Вернуть новость из архива?
        </v-card-title>

        <v-card-text>
          После возврата новости из архива он снова будет отображаться на сайте.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmUnArchiveDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="unarchive(selectedNews)"
          >
            Вернуть из архива
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import moment from 'moment'
  import newsApi from '../services/NewsApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    filters: {
      formatBirthday: function (date) {
        return moment(date).format('DD.MM.YYYY')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    props: ['state'],
    data () {
      return {
        newsCategoryFilter: CRMStorage.getItem('NL_newsCategoryFilter', []),
        newsCategories: [],
        search: CRMStorage.getItem('NL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('NL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('NL_onlyArchived', false),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: false,
            text: ' ',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Новость',
            value: 'text',
            visible: true,
          },
          {
            sortable: true,
            text: 'Дата',
            value: 'create_time',
          },
          {
            sortable: true,
            text: 'Категория',
            value: 'newscategory_id',
            filter: value => {
              if (!Array.isArray(this.newsCategoryFilter) || this.newsCategoryFilter.length <= 0) return true
              return this.newsCategoryFilter.includes(value)
            },
          },
          {
            sortable: false,
            text: ' ',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
        ],
        items: [],
        loading: true,
        newsDialog: false,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        news: {
          name: null,
          text: null,
          newscategory_id: null,
          url: null,
        },

        confirmArchiveDialog: false,
        confirmUnArchiveDialog: false,
        selectedNews: null,
      }
    },
    watch: {
      newsCategoryFilter: function (val) {
        this.page = 1
        CRMStorage.setItem('NL_newsCategoryFilter', val)
      },
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('NL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('NL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('NL_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('NL_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('NL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('NL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Новости | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10

      newsApi
        .fetchNewsCategories()
        .then(response => {
          this.newsCategories = this.newsCategories.concat(response)
        })
        .catch(error => {
          this.handleError(error)
        })

      newsApi.fetchNewses()
        .then(response => {
          this.items = this.items.concat(response)
          this.loading = false
        })
        .catch(error => {
          this.handleError(error)
        })
    },
    methods: {
      singleNews (news) {
        CRMStorage.setItem('NL_scrollY', window.scrollY)
        this.$router.push('/news/' + news.id)
      },
      createNews (news) {
        CRMStorage.setItem('NL_scrollY', window.scrollY)
        this.$router.push('/news/create')
      },
      singleNewsLink (news) {
        return process.env.VUE_APP_OLD_URL + 'news/link/' + news.id + '?_rid=' + moment().unix()
      },
      create () {
        this.error = null
        this.fieldsErrors = []
        this.loading = true

        newsApi
          .createNews(this.news)
          .then(response => {
            this.news = response
            this.newsDialog = false
            this.$router.push('/news/' + this.news.id)
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      update (news) {
        if (news.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          newsApi
            .updateNews(news)
            .then(response => {
              newsApi.fetchNewses()
                .then(response => {
                  this.items = this.items.concat(response)
                  this.loading = false
                })
                .catch(error => {
                  this.handleError(error)
                })
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      },
      confirmArchive (news) {
        this.selectedNews = news
        this.confirmArchiveDialog = true
      },
      confirmUnArchive (news) {
        this.selectedNews = news
        this.confirmUnArchiveDialog = true
      },
      archive (news) {
        this.confirmArchiveDialog = false
        news.status = 0
        this.update(news)
      },
      unarchive (news) {
        this.confirmUnArchiveDialog = false
        news.status = 1
        this.update(news)
      },
      filterNew () {
        this.state = 'new'
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      compareArrays (first, second) {
        if (!first || !second) return false
        return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e))
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },

      getFiltered (items) {
        const selectedIds = items.map(({ id }) => id)
        CRMStorage.setItem('NL_newssIds', selectedIds)
      },

      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 'i/100/100/' + id
      },
    },
  }
</script>
